@use "./variables" as *;

@mixin tabletStyle {
  @media screen and (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktopStyle {
  @media screen and (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin logoStyles {
  font-size: 1rem;
  font-weight: bolder;

  border: solid black 1px;
  border-radius: 50%;
  padding: 0.3rem;

  @include tabletStyle {
    font-size: 3rem;
    margin: unset;

    padding: 0.6rem;
  }

  @content;
}
