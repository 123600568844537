@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.intro {
  @include tabletStyle {
    margin: $tablet-margins;
  }

  @include desktopStyle {
    display: flex;
    margin: $desktop-margins;
    margin-bottom: unset;
  }

  &__title,
  &__paragraph {
    @include tabletStyle {
      text-align: center;

      font-size: 1rem;
    }

    @include desktopStyle {
      font-size: 1.2rem;
    }
  }

  &__title {
    font-size: 1.5rem;
    color: $dramatic-overlay;
    margin-bottom: 0.5rem;
    font-weight: bold;

    @include desktopStyle {
      margin-top: unset;
      font-size: 1.75rem;
    }
  }

  &__paragraph {
    margin: unset;

    @include tabletStyle {
      font-size: 1rem;
      margin-top: unset;
      margin: 2rem 7rem;
    }

    @include desktopStyle {
      width: 75%;
      margin: auto;
      font-size: 1.25rem;
    }
  }

  &__txt-box {
    margin: $mobile-margins;
  }
}

.intro__learn-more-btn {
  background-image: linear-gradient(135deg, red, red);
  box-sizing: border-box;
  color: $dramatic-overlay;
  display: block;
  height: 35px;
  font-size: 1rem;
  font-weight: 300;
  padding: 1px;
  position: relative;
  text-decoration: none;
  width: 7em;
  z-index: 2;

  margin: $mobile-margins;

  @include tabletStyle {
    display: none;
  }

  @include desktopStyle {
    margin: auto;
  }
}

.intro__learn-more-btn:hover {
  color: #fff;
}

.intro__learn-more-btn .intro__learn-more-btn-span {
  align-items: center;
  background: white;
  //border-radius: 6px;
  display: flex;
  justify-content: center;
  height: 100%;
  transition: background 0.5s ease;
  width: 100%;
}

.intro__learn-more-btn:hover .intro__learn-more-btn-span {
  background: transparent;
}

.intro__learn-more-btn-box {
  @include tabletStyle {
    display: flex;
    justify-content: center;
  }

  @include desktopStyle {
    margin: $desktop-margins;
    justify-content: end;
    margin-top: unset;
  }
}
