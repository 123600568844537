@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.testimonial {
  &__box {
    margin: $mobile-margins;

    background-color: $charcoal-grey;
    border-radius: 0.755rem;
    padding: 0.75rem;
  }

  &__quote {
    //font-size: 0.8rem;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    //font-style: italic;
    color: white;
    margin: unset;
    display: inline;

    @include tabletStyle {
      font-size: 1rem;
    }

    @include desktopStyle {
      font-size: 1.2rem;
    }
  }

  &__individual {
    text-align: right;
    font-size: 0.8rem;
    color: white;
    margin-top: unset;
    margin-top: unset;

    @include tabletStyle {
      margin-top: 1.25rem;
    }
  }

  &__viewMore {
    text-align: center;
    font-size: 0.8rem;
    color: white;
    font-weight: 100;
    font-style: italic;

    @include tabletStyle {
    }

    &:hover {
      font-weight: bold;
      transition: font-weight 1s;
    }
  }

  &__quote-icon {
    width: 1rem;
    display: inline;
    position: relative;
    top: 0px;
    margin-right: 0.625rem;
  }
}

.modal {
  background-color: $charcoal-grey;
  padding: 1rem;
  border-radius: 0.755rem;
  margin: $mobile-margins;

  @include tabletStyle {
    padding: 1.5rem;
  }
}
