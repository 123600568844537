@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.wrapDesc {
  @include tabletStyle {
    width: 45%;
  }

  @include desktopStyle {
    width: 30%;
    margin-top: 1rem;
  }

  &__header {
    font-size: 1.25rem;
    margin-top: unset;
    margin-bottom: unset;
    padding-top: 1rem;

    @include desktopStyle {
      font-size: 2rem;
    }
  }

  &__paragraph {
    font-size: 0.85rem;

    @include tabletStyle {
      height: 25%;
      margin-bottom: 2rem;
      font-size: 1rem;
    }
    @include desktopStyle {
      height: 20%;
      font-size: 1.25rem;
      margin-bottom: 6rem;
    }
  }

  &__period {
    color: red;
  }

  &__img {
    width: 100%;
    height: 15rem;
    border-radius: 0.6rem;
    object-fit: cover;
    object-position: center top;
    filter: brightness(.9);

    @include desktopStyle {
      height: 25rem;
    }
  }
}
