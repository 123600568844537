@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.fourOhFour {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

  &__warning {
    margin: $mobile-margins;
    text-align: center;
  }

  &__homeLink {
    color: black;
    border: 1px solid black;
    padding: 1rem;
    border-radius: 30px;
  }
}
