@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.ourWrapPage {
  margin: $mobile-margins;

  @include tabletStyle {
    margin: $tablet-margins;
  }

  @include desktopStyle {
    margin: 0 20%;
  }

  @media screen and (min-width: 1500px) {
    width: 80%;
    margin: auto;
  }
}

.ourWrapIntro {
  border: 1px solid black;
  border-radius: 6px;
  padding: 1rem;

  @include tabletStyle {
    width: 60%;
    margin: auto;
    height: 20rem;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @include tabletStyle {
      flex-direction: row;
      margin-bottom: 2.5rem;
    }
  }

  &__paragraph {
    font-size: 1rem;
    font-weight: 100;

    @media screen and (min-width: 950px) {
      font-size: 1.2rem;
    }

    @include desktopStyle {
      font-size: 1rem;
    }

    @media screen and (min-width: 1518px) {
      font-size: 1.3rem;
    }
  }

  &__productParagraph {
    font-weight: 100;

    @include tabletStyle {
      font-size: 1.2rem;
    }

    @include desktopStyle {
      font-size: 1.3rem;
    }
  }

  &__img {
    &Mobile {
      width: 100%;
      height: 13rem;
      object-fit: cover;
      border-radius: 0.625rem;
      filter: brightness(1.15);
      filter: saturate(1.5);

      @include tabletStyle {
        display: none;
      }
    }

    &Tablet {
      display: none;

      @include tabletStyle {
        display: inline-block;
        width: 35%;
        height: 13rem;
        object-fit: cover;
        border-radius: 0.625rem;
        filter: brightness(1.15);
        filter: saturate(1.5);
        float: left;
        margin: 0 1.25rem 1.25rem 0;
      }

      @include desktopStyle {
        margin: 1rem 2.2rem 2.2rem 1rem;
      }
    }
  }

  &__imageBox {
    background-image: url("../../assets/pictures/ssstills/IMG_1107-min.JPG");
    transition: background 1.25s linear;
    filter: brightness(1.05);
    background-size: cover;
    width: 100%;
    height: 20rem;
    margin: 1rem;

    border-radius: 0.9375rem;

    @include tabletStyle {
      width: 40%;
      margin-right: unset;
    }

    &p {
      height: 100%;
    }
  }

  &__header {
    font-size: 2rem;
    margin-bottom: unset;
  }

  &__productContent {
    border: 1px solid black;
    border-radius: 6px;
    padding: 1rem;
    margin-right: unset;

    @include tabletStyle {
      display: flex;
    }
  }
}

.ourWrap__container {
  @media screen and (min-width: 1500px) {
    display: flex;
    margin-bottom: 4rem;
  }
}

.left {
  @media screen and (min-width: 1500px) {
    margin-left: 2rem;
  }
}
