@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.wrapBulletPoint {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  margin: 2rem 0;

  background-color: white;
  border: 2px solid black;
  border-radius: 12px;
  padding: 1rem;

  &:hover {
    background-color: lightgray;
    transition: background-color .75s ease;
  }

  &__copy {
    font-size: 0.75rem;
    width: 75%;
    margin: unset;
    padding-right: .5rem;

    @include desktopStyle {
      font-size: 1.25rem;
      width: 75%;
    }
  }

  &__indent {
    color: red;
    font-weight: bolder;
    padding: 0 0.2rem;
  }

  &__img {
    object-fit: cover;

  }
}
