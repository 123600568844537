@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.wrapDescBox {
  background-color: lightgray;
}

.homePage {
  &__wrapIntro {
    margin: 0 $mobile-margins;

    &__section {
      @include tabletStyle {
        width: 65%;
        text-align: center;
        margin: auto;
      }

      @include tabletStyle {
        width: 65%;
      }

      @include desktopStyle {
        width: 45%;
        text-align: left;
        margin-top: 0.7rem;
        margin-bottom: 3.5rem;
      }
    }

    &__title {
      margin-bottom: unset;
      border-bottom: 1px solid black;

      @include desktopStyle {
        font-size: 2rem;
      }
    }

    &__paragraph {
      font-size: 0.8rem;
      font-weight: 100;

      @include tabletStyle {
        font-size: 1.1rem;
        margin-bottom: 2rem;
      }

      @include desktopStyle {
        font-size: 1.4rem;
      }
    }

    &Cards {
      @include tabletStyle {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 4rem;
      }
    }
  }

  &__ourWrap {
    padding: $mobile-margins;
    margin: $mobile-margins 0;

    background-color: lightgray;

    &__header {
      font-size: 1.5rem;
      text-align: center;
      margin-bottom: unset;

      @include desktopStyle {
        font-size: 2rem;
        margin-top: 3rem;
      }
    }

    &__p {
      font-weight: 100;
      font-size: 0.7rem;
      text-align: center;
      padding: 0 2rem;

      @media screen and (min-width: 500px) {
        font-size: 1.1rem;
      }

      @include tabletStyle {
        padding: 0 4rem;
      }

      @include desktopStyle {
        font-size: 1.5rem;
        width: 75%;
        margin: 1rem auto;
      }

      @media screen and (max-width: 320px) {
        padding: unset;
      }
    }

    &__intro {
      width: 70%;
      margin: auto;
    }

    &__list {
      @include tabletStyle {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        margin: $tablet-margins;
      }

      @include desktopStyle {
        grid-template-columns: repeat(3, 1fr);

        margins: $desktop-margins;
      }
    }
  }

  &__contact-section {
    margin: $mobile-margins;

    @include tabletStyle {
      margin: 0 10rem;
    }

    @include desktopStyle {
      margin: 0 25%;
    }
  }

  &__testimonial {
    @media (min-width: 500px) {
      &__first {
        width: 70%;
      }
    }

    &__tablet {
      display: none;

      @include tabletStyle {
        display: inline-block;
      }
    }

    &__other {
      display: none;

      @include tabletStyle {
        display: flex;
      }
    }

    &__first,
    &__second,
    &__other {
      width: 100%;
      height: 100%;
    }
    &__second {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    &__other {
      @media screen and (max-width: 1000px) {
        display: none;
      }
    }
    &__pair {
      background-position: center;
      background-size: cover;
      padding: 1rem 0;
      display: flex;

      margin: $mobile-margins;

      @include tabletStyle {
        margin: $tablet-margins;
        margin-top: unset;
      }

      @include desktopStyle {
        margin: $desktop-margins;
        margin-top: unset;
      }
    }
  }
}
