@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.testimonialsPage {
  &__header {
    text-align: center;
    margin: $mobile-margins;
    margin-bottom: unset;
    border-bottom: 2px solid black;

    @include tabletStyle {
      margin: 0 $tablet-margins;
    }

    @include desktopStyle {
      margin: 1 $desktop-margins 0;
    }
  }

  &__container {
    @include tabletStyle {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.1rem;
      margin: $tablet-margins;
    }

    @include desktopStyle {
      grid-template-columns: repeat(3, 0.5fr);
      margin: $desktop-margins;
      margin-top: unset;
    }

    @media screen and (min-width: 1801px) {
      grid-template-columns: repeat(4, 0.5fr);
    }
  }
}
