//Color Pallete A
$highlight-accent: #c9fbffff;
$subtle-background: #c2fcf7ff;
$accent-text: #85bdbfff;
$deep-shadow: #57737aff;
$dramatic-overlay: #040f0fff;

//Color Pallete B
$primary-color: #d7f2ba;
$secondary-color: #bde4a8;
$highlight-color: #79b4a9;
$Accent-color: #9cc69b;
$text-color:#676F54;


//other colors
$charcoal-grey:#333333;

//Breakpoints
$tablet-breakpoint: 767px;
$desktop-breakpoint: 1280px;

//Margins
$mobile-margins: 1rem;
$tablet-margins: 2rem;
$desktop-margins: 3.9rem;
