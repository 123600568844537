@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.contactPage {
  &__hero {
    background-image: url(../../assets/pictures/PHOTO-2023-12-29-02-08-02.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 12rem;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include tabletStyle {
      height: 25rem;
      width: 100%;
    }
  }

  &__heroHeader {
    font-size: 2rem;
    font-weight: bold;
    margin-top: unset;
    margin-bottom: unset;
  }

  &__container {
    @include tabletStyle {
      display: flex;
      justify-content: space-between;
    }

    @include desktopStyle {
      justify-content: space-around;
    }
  }

  &__form {
    @include tabletStyle {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include desktopStyle {
        margin: auto;
      }
    }
  }

  &__details {
    padding: $mobile-margins;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;
    margin: auto;
    border-radius: 25px;
    text-align: center;
    background-color: lightgray;
    color: black;
    border-radius: 0 30px 0 30px;
    margin-bottom: 4rem;


    p > a {
      color: black;
    }

    @include tabletStyle {
      background-color: lightgray;
      align-items: flex-start;
      border-radius: 0 0 0 30px;
      border: unset;
      width: unset;
      margin: unset;
      text-align: left;
    }

    @include desktopStyle {
      align-items: center;
      width: 35%;
    }
  }
}
