@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.footer {
  background-color: $charcoal-grey;
  text-align: center;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;

  width: 100%;

  margin-top: auto;

  @include tabletStyle {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10%;
  }

  @include desktopStyle {
    padding: 0 $desktop-margins;
    margin-top: 2rem;
    padding-top: 1.5rem;
  }

  &__ctaQmark {
    color: red;
  }

  &__ctaBox {
    @include tabletStyle {
      display: flex;
    }
  }

  &__ctaLogo {
    @include logoStyles;
    border: 1px solid white;
    width: 2rem;
    border-bottom: 2px solid white;
    margin: auto;

    @include tabletStyle {
      width: 4rem;
      font-size: 2rem;
      margin-right: 0.5rem;
    }
  }

  &__ctaPhone {
    @include tabletStyle {
      display: none;
    }
  }

  &__ctaGiveCall,
  &__ctaHaveQs {
    margin: auto 0.5rem;
    @include tabletStyle {
      order: 1;
    }
  }

  &__copyright {
    font-size: 0.75rem;
    font-weight: 100;
    background-color: $charcoal-grey;

    & > p {
      color: white;
      text-align: center;
      margin: unset;
      padding: 1rem;

      @include desktopStyle {
        margin: 0 $desktop-margins;
      }
    }
  }

  &__contact {
    display: none;

    @include tabletStyle {
      display: inline-block;
      text-align: right;
    }
  }

  &__container {
    margin-top: auto;
  }

  & > p {
    margin: 0.35rem;
  }
}

a {
  text-decoration: none;
  color: white;
}
