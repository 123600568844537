@use "./variables" as *;
* {
  //gitoutline: 1px solid red;
  box-sizing: border-box;
}

html {
  margin: unset;
}

body {
  margin: unset;
  display: flex;
  flex-direction: column;
  margin-top: auto;

  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
