@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.contact {
  &__intro {
    text-align: center;
    margin-bottom: unset;
    font-size: 1rem;
    font-weight: bold;
    margin: 2rem;

    @include tabletStyle {
      width: 60%;
      margin: auto;
      margin-top: 2em;
    }

    @include desktopStyle {
      margin: 3.5rem 0rem;
      font-size: 1.5rem;
      width: 75%;
      margin: auto;
      margin-top: 3rem;
    }
  }

  &__introEmail {
    margin-top: unset;
    text-align: center;
    padding: 0 0.3rem;

    a {
      color: red;
    }
  }

  &__header {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.5rem 0;
    margin-bottom: 1rem;
    margin: $mobile-margins;

    @include tabletStyle {
      //background-color: pink;
      text-align: center;
      padding: 1rem;
      padding-bottom: unset;
    }

    @include desktopStyle {
      font-size: 3rem;
    }
  }

  &__success {
    background-color: lightgray;
    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0.9375rem;
    margin: 3rem;
    font-size: 1rem;
    padding: 1rem;
    font-weight: bold;
    text-align: center;
    //border: 1px solid black;

    @media screen and (min-width: 525px) {
      width: 65%;
      margin: 2rem auto;
    }

    @include tabletStyle {
      font-size: 1.5rem;
      display: flex;
      flex-direction: row;
      width: 85%;
    }

    @include desktopStyle {
      margin: 6rem auto;
    }
  }

  &__successImg {
    width: 50%;

    @media screen and (min-width: 525px) {
      width: 40%;
      margin: 0 auto;
      height: 6rem;
      //margin-left: 1rem;
    }

    @include tabletStyle {
      width: 7.5rem;
      height: 25%;
      margin: auto;
      margin-left: 1rem;
    }

    @media screen and (min-width: 1920px) {
      margin: auto;
    }
  }

  &__successCopy {
    margin-top: 1rem;

    @include tabletStyle {
      //border: 1px solid black;
      border-radius: 0.9375rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      padding: 1rem;
      margin: auto;
    }
  }

  @media screen and (min-width: 1920px) {
    margin: auto;
  }
}

.contactForm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin: 2rem;
  margin-bottom: 4rem;

  &__message {
    grid-column: span 2;
  }

  &__submitBtn {
    background-color: red;
    border: unset;
    border-radius: 0.1875rem;
    grid-column: span 2;
    height: 2rem;
    color: white;
  }
}

.contactForm__fname,
.contactForm__lname,
.contactForm__phone,
.contactForm__email,

.contactForm__message {
  @include tabletStyle {
    display: flex;
    flex-direction: column;
  }
}

.contactForm__fnameInput,
.contactForm__lnameInput,
.contactForm__phoneInput,
.contactForm__emailInput,
.contactForm__messageInput {
  border: unset;
  background-color: lightgray;
  width: 100%;
  height: 2.25rem;
  border-radius: 0.2rem;
  padding: 0.5rem;
}

.contactForm__messageInput {
  height: 6rem;
  resize: none;

}

input::placeholder {
  font-size: 0.6rem;

  @include tabletStyle {
    font-size: 0.8rem;
  }
}
