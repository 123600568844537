@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;

  width: 100%;

  a {
    color: black;
    text-decoration: none;
  }

  @include tabletStyle {
    justify-content: space-between;
    padding: 0 $tablet-margins;
    margin-right: unset;
    flex-direction: row;
  }

  @include desktopStyle {
    margin: unset;
  }

  &__logo-box {
    display: flex;
    align-items: center;
  }

  &__logo {
    @include logoStyles;

    margin-top: 1.5rem;

    @include tabletStyle {
      margin: 1rem 0;
      font-size: 1rem;
    }

    @include desktopStyle {
      margin-left: $desktop-margins;
    }

    &:hover {
      background-color: black;
      color: white;
      transition: background-color 0.5s ease;
    }
  }

  &__name {
    font-size: 1rem;
    margin-left: 1.5rem;
    margin-top: 1.5rem;

    @include tabletStyle {
      font-size: 2rem;
    }
  }

  &__nav {
    //display: none;
    display: flex;
    margin: unset;
    font-size: 0.4rem;
    padding: unset;
    font-weight: bold;

    @media screen and (min-width: 515px) {
      font-size: 0.75rem;
    }

    @include tabletStyle {
      display: flex;
      justify-content: space-around;
      padding-left: unset;
      font-size: 0.6rem;
      font-weight: 400;
    }

    @media screen and (min-width:900px) {
      font-size: .9rem;
    }

    @include desktopStyle {
      font-size: 1.1rem;
      margin-right: $desktop-margins;
    }
  }

  &__link {
    list-style-type: none;
    padding: 0.25rem 0.75rem;

    @include tabletStyle {
      padding-top: 1rem;
      margin: auto;
      padding: .5rem 1rem;
      
    }

    &:hover {
      color: red;
      transition: color 0.15s;
    }
  }

  &__phone {
    order: 2;
    list-style-type: none;
    padding: 1rem;

    @include tabletStyle {
      order: -2;
      padding-top: 1rem;
      margin: auto;
    }
  }
}

a {
  text-decoration: none;
  color: black;
}

.active {
  border: 1px solid rgba($color: red, $alpha: 0.15);
  border-bottom: solid 2px rgba($color: red, $alpha: 0.5);
  border-radius: 10px;
}

.testtt {
  display: flex;
  align-items: center;
}
