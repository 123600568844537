@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.hero {
  &__imgBox {
    background-image: url("../../assets/pictures/ssstills/IMG_0919-min.JPG");
    background-size: cover;
    background-position: center;
    transition: background 1s linear;

    position: relative;
    height: 20rem;

    display: flex;
    align-items: center;
    justify-content: center;

    @include tabletStyle {
      justify-content: flex-start;
    }

    @include tabletStyle {
      height: 30rem;
    }

    @include tabletStyle {
      height: 40rem;
    }
  }

  &__content {
    position: rela;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    color: white;

    background-color: rgba(255, 255, 255, 0.4);
    width: 60%;
    height: 50%;
    border-radius: 0.5625rem;
    padding: 1rem;
    margin: auto;

    @media screen and (min-width: 525px) {
      height: 70%;
    }

    @media screen and (min-width: 499px) {
      width: 40%;
    }

    @include tabletStyle {
      height: unset;
      width: 40%;
      padding: 1.5rem;
      //margin-left: $tablet-margins;
    }

    @include desktopStyle {
      padding: 2.5rem;
    }
  }

  &__logline {
    color: black;
    z-index: 99;
    margin: unset;

    @include tabletStyle {
      font-size: 3rem;
      text-align: center;
    }

    &-dot {
      color: red;
      font-size: 2rem;

      @include tabletStyle {
        font-weight: bolder;
        margin: unset;
      }
    }

    @media screen and (max-width: 320px) {
      font-size: 0.8rem;
    }
  }

  &__desc {
    font-size: 0.5rem;
    text-align: center;
    color: black;

    @media screen and (min-width: 525px) {
      font-size: 1rem;
    }

    @include tabletStyle {
      font-size: 1.5rem;
    }

    @include desktopStyle {
      font-size: 2rem;
      font-weight: 100;
    }
  }

  &__btns {
    display: flex;
    flex-direction: column;
    border: unset;

    @include tabletStyle {
      justify-content: center;
    }

    @media screen and (min-width: 999px) {
      flex-direction: row;
    }
  }
}

.btn {
  display: inline-block;
  padding: 0.6em 1.7em;
  //border: 0.1em solid #ffffff;
  margin: 0.3rem 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 0.75rem;
  color: #ffffff;
  background-color: red;
  text-align: center;
  transition: all 0.2s;
  border-radius: 15px;
  width: 9rem;

  @include tabletStyle {
    margin: 1rem;
  }
}

.btn:hover {
  color: #000000;
  background-color: #ffffff;
}
